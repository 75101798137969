<template>
    <aside class="c-aside c-aside-sm">
        <nav class="c-nav">
            <div class="c-aside__apps">
                <el-tooltip v-for="item in apps" :key="item.key" :content="item.label" placement="right">
                    <div class="u-app-item" :class="{ active: item.key === app }" @click="toggleApp(item)">
                        <img v-svg-inline :src="imgSrc(item.key)" alt="" />
                    </div>
                </el-tooltip>
                <div class="m-bottom">
                    <el-tooltip :content="$t('common.mini_nav.support')" placement="right">
                        <img
                            class="u-app-item u-question"
                            src="../../assets/img/icon/support.svg"
                            @click="toFeedback"
                        />
                    </el-tooltip>
                    <el-tooltip :content="$t(`common.mini_nav.${!navExpanded ? 'expand' : 'fold'}`)" placement="right">
                        <img
                            class="u-app-item u-toggle"
                            :class="{ 'u-toggle--active': navExpanded }"
                            src="../../assets/img/icon/expanded.svg"
                            @click="toggle"
                        />
                    </el-tooltip>
                </div>
            </div>
            <div class="c-aside__toggle"></div>
        </nav>
    </aside>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { apps } from "@/assets/data/config/app";
export default {
    name: "MiniNav",
    data() {
        return {
            apps,
        };
    },
    computed: {
        ...mapState(["navExpanded", "app"]),
    },
    methods: {
        ...mapMutations({
            setNav: "SET_NAV",
            setApp: "SET_APP",
        }),
        toggle() {
            this.setNav(!this.navExpanded);
        },
        imgSrc(key) {
            return require(`../../assets/img/app/${key}.svg`);
        },
        toggleApp(item) {
            // this.setApp(item.key);
            location.href = item.external || "/";
        },
        toFeedback() {
            this.$router.push({ name: "dashboard-feedback-index" });
        },
    },
    mounted() {
        const app = sessionStorage.getItem("app");

        // 需要排序apps，app优先，排除pinned
        if (app) {
            const pinned = this.apps.filter((item) => item.pinned);
            const unpinned = this.apps
                .filter((item) => !item.pinned)
                .sort((a, b) => {
                    if (a.key === app) {
                        return -1;
                    } else if (b.key === app) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            this.apps = pinned.concat(unpinned);
        }
    },
};
</script>

<!-- <style lang="less">
@import "~@/assets/css/common/nav.less";
</style> -->
