export default {
    nav: {
        submit: "提交工单",
        list: "我的工单",
    },
    submit: {
        type: "应用",
        type_placeholder: "请选择",
        sub: "类型",
        subtype_placeholder: "请选择子类型",
        subtype: {
            bug: "Bug异常",
            suggestion: "建议意见",
            report: "举报投诉",
            other: "其它",
        },
        content: "内容",
        content_placeholder: "请输入内容",
        image: "图片",
        image_max: "上传的图片个数最多为{max}个",
        image_size: "单个图片大小不能超过10M",
        btn: "提交",
    },
    table: {
        close: "关闭工单",
        cancel: "取消工单",
        close_tip: "是否要取消工单？",
        batch_close_tip: "是否要取消已选工单？",
        status: {
            all: "全部",
            complete: "已完成",
            0: "待处理",
            1: "已指派",
            2: "处理中",
            3: "已处理",
            "-1": "已取消",
            "-2": "已关闭",
        },
        status_text: "状态",
        subtype: "类型",
        content: "问题",
        created_at: "提交时间",
        updated_at: "最新处理",
    },
    detail: {
        user: "提交人",
        time: "提交时间",
        refer: "来源",
        assign: "指派至",
        coordinator: "协同人",
        repo: "仓库",
        content: "反馈内容",
        log: "日志记录",
        img: "附件截图",
        operator: "操作人",
        replenish: "补充",
        reply_placeholder: "请输入回复",
    },
    action: {
        create: "创建",
        use_close: "取消",
        update: "更新",
        admin_close: "关闭",
        transfer: "转交",
        assign: "指派",
        coordinate: "协同",
        start: "开始处理",
        done: "处理完成",
        reopen: "重新打开",
    },
};
