import { i18n } from "@/locale";

const {
    global: { t },
} = i18n;

import layout from "@/layouts/dashboard";

export default [
    {
        path: "/dashboard/feedback",
        name: "feedback",
        component: layout,
        hidden: true,
        redirect: {
            name: "dashboard-feedback-index",
        },
        children: [
            {
                path: "index/:type(\\w+)?",
                name: "dashboard-feedback-index",
                component: () => import("@/views/feedback/index"),
                meta: {
                    title: t("common.routes.feedbackTitle"),
                    desc: t("common.routes.feedbackDesc"),
                },
            },
            {
                path: "detail/:id(\\d+)",
                name: "dashboard-feedback-detail",
                component: () => import("@/views/feedback/detail"),
                meta: {
                    title: t("common.routes.feedbackTitle"),
                    desc: t("common.routes.feedbackDesc"),
                },
            },
        ],
    },
];
