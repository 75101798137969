export default {
    nav: {
        submit: "Feedback",
        list: "My Feedback",
    },
    submit: {
        type: "Application",
        type_placeholder: "Please select",
        sub: "Type",
        subtype_placeholder: "Please select a subtype",
        subtype: {
            bug: "Bugs",
            suggestion: "Suggestions",
            report: "Report",
            other: "Other",
        },
        content: "Content",
        content_placeholder: "Please enter the content",
        image: "Images",
        image_max: "The maximum number of images that can be uploaded is {max}",
        image_size: "The size of a single image cannot exceed 10M",
        btn: "Submit",
    },
    table: {
        close: "Close",
        cancel: "Cancel",
        close_tip: "Do you want to cancel the ticket?",
        batch_close_tip: "Do you want to cancel the selected tickets?",
        status: {
            all: "All",
            complete: "Completed",
            0: "Pending",
            1: "Assigned",
            2: "Processing",
            3: "Resolved",
            "-1": "Cancelled",
            "-2": "Closed",
        },
        status_text: "Status",
        subtype: "Type",
        content: "Issue",
        created_at: "Created At",
        updated_at: "Last Follow-up",
    },
    detail: {
        user: "Submitted By",
        time: "Submission time",
        refer: "Refer",
        assign: "Assigned To",
        coordinator: "Collaborator",
        repo: "Repository",
        content: "Details",
        log: "Activity Log",
        img: "Attachment",
        operator: "Operator",
        replenish: "Reply",
        reply_placeholder: "Please enter the reply",
    },
    action: {
        create: "Create",
        use_close: "Cancel",
        update: "Update",
        admin_close: "Close",
        transfer: "Transfer",
        assign: "Assign",
        coordinate: "Coordinate",
        start: "Start",
        done: "Done",
        reopen: "Reopen",
    },
};
